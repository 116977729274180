exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-en-tsx": () => import("./../../../src/pages/contact.en.tsx" /* webpackChunkName: "component---src-pages-contact-en-tsx" */),
  "component---src-pages-contact-pl-tsx": () => import("./../../../src/pages/contact.pl.tsx" /* webpackChunkName: "component---src-pages-contact-pl-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-pl-tsx": () => import("./../../../src/pages/index.pl.tsx" /* webpackChunkName: "component---src-pages-index-pl-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-en-tsx": () => import("./../../../src/pages/projects.en.tsx" /* webpackChunkName: "component---src-pages-projects-en-tsx" */),
  "component---src-pages-projects-pl-tsx": () => import("./../../../src/pages/projects.pl.tsx" /* webpackChunkName: "component---src-pages-projects-pl-tsx" */)
}

